import axios from "axios";
import { handleLogout } from "../redux/index";
const API = axios.create({
  // baseURL:"https://nginx9.com/api",
  // baseURL: "https://11wicket.bet/api",
  baseURL: process.env.REACT_APP_DEV_BASEURL,
  timeout: 1000 * 20, // 20 seconds
});

//Add a response interceptor
const getToken = () => {
  return sessionStorage.getItem("jwt_token");
};
API.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    if (response?.data) {
      return response;
    }
    if (response?.status === 401) {
      sessionStorage.clear();
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log("***********Unauthorized Error**********");
      handleLogout();
      sessionStorage.clear();
      const token = sessionStorage.getItem("jwt_token");
      if (token) {
      }
    }
    return Promise.reject(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message
    );
  }
);

export default API;
