import axios from "axios";
import { store } from '../redux/store';
export const authAxios = () => {
   // const user = useAppSelector((state: any) => state.auth);
    let user = store.getState().auth;
    return axios.create({
        baseURL: process.env.REACT_APP_DEV_BASEURL,
        headers: {
            'Authorization' : `Bearer ${user?.userData?.Token}`,
        },
    });
};

export const withoutAuthAxios = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_DEV_BASEURL,
    });
};